import React, { useState, useContext, useEffect } from "react";
import styles from './index.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import { useIntl } from 'react-intl'
import MainContext from '../../stores/stores'
import accountApi from "@/api/accountApi";

const Mine: React.FC<any> = (props) => {
  const { store, setStore } = useContext(MainContext)
	const { isCst, distributorInfo } = store
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([pathname])
  const [items, setItems] = useState<any[]>([])
  /* useEffect(() => {
    let _userInfo = JSON.stringify(store.userInfo)
    if(_userInfo === '{}') {
      getUserInfo()
    }
    let _distributorInfo = JSON.stringify(store.distributorInfo)
    if(_distributorInfo === '{}') {
      getDistributorInfo()
    }
    getIsCst()
  }, []) */
  useEffect(() => {
    setSelectedKeys([pathname])
  }, [pathname])
  
  useEffect(() => {
    let companyItems = store.userInfo && store.userInfo.is_repair_shop_admin ? [
      {
        label: intl.formatMessage({ id: '公司管理' }),
        key: '/mine/company'
      },
    ] : []
    
    let invitationItem  = distributorInfo.status ? [
      {
        label: intl.formatMessage({ id: '邀请码' }),
        key: '/mine/invitation'
      }
    ]: []
    const _items: any = store.userInfo && store.userInfo.verify_status && store.userInfo.verify_status === 1 ? [
      {
        label: <span>{intl.formatMessage({ id: '订单列表' })}</span>,
        key: '/mine/order'
      },
      {
        label: intl.formatMessage({ id: '退款/售后' }),
        key: '/mine/aftersales',
        cstnot: istest ? 0 : 1
      },
      {
        label: intl.formatMessage({ id: '对账单' }),
        key: '/mine/reconciliation',
        cstnot: istest ? 0 : 1
      },
      {
        label: intl.formatMessage({ id: '应付单' }),
        key: '/mine/copewith',
        cstnot: istest ? 0 : 1
      },
      {
        label: intl.formatMessage({ id: '地址管理' }),
        key: '/mine/address'
      },
      {
        label: intl.formatMessage({ id: '发票抬头管理' }),
        key: '/mine/invoice',
        cstnot: istest ? 0 : 1
      },
      ...companyItems,
      {
        label: intl.formatMessage({ id: '设置' }),
        key: '/mine/setting'
      },
      ...invitationItem,
    ] : [
      {
        label: intl.formatMessage({ id: '地址管理' }),
        key: '/mine/address'
      },
      {
        label: intl.formatMessage({ id: '发票抬头管理' }),
        key: '/mine/invoice',
        cstnot: istest ? 0 : 1
      },
      ...companyItems,
      {
        label: intl.formatMessage({ id: '设置' }),
        key: '/mine/setting'
      },
      ...invitationItem
    ]
    setItems(_items)
  }, [store.userInfo, store.distributorInfo])
	
	const istest: number = window.location.host.includes("webtest") || window.location.host.includes("localhost:300") ? 1 : 0

  const getDistributorInfo = () => {
		accountApi.getDistributorInfo().then((res: any) => {
			if(res.code === 1) {
				setStore({value: res.data, type: 'distributorInfo'})
			}
		})
	}
  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        res.data.company = res.data.reg_company_name
        localStorage.setItem('uid', res.data.uid)
        setStore({value: res.data, type: 'userInfo'})
      }
    })
  }
  const getIsCst = () => {
	  accountApi.getIsCst().then((res: any) => {
	    if(res.code === 1) {
				setStore({value: res.data.is_cst, type: 'isCst'})
	    }
	  })
	}
  const goTo = (key: string) => {
    navigate(key)
    setSelectedKeys([key])
  }
  
  return (
    <div className={styles['mine']}>
      <div className="flex">
        <Menu
          style={{ width: window.localStorage.getItem("lang") === 'zh' ? 160 : 225, marginRight: 10 }}
          selectedKeys={selectedKeys}
          items={items.filter((it: any) => !isCst || !it.cstnot)}
          onClick={(e: any) => goTo(e.key)}
        />
        <div className="flex-1" style={{ background: '#fff', minHeight: 'calc(100vh - 290px)', width: window.localStorage.getItem("lang") === 'zh' ? 'calc(100% - 170px)' : 'calc(100% - 235px)' }}>
          <Outlet/>
        </div>
      </div>
      
      
    </div>
  )
}

export default Mine